@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  @apply bg-gray-100 text-gray-900;
}

.wrapper {
  @apply bg-dark-blue-800 text-gray-100;
}

h1, h2, h3, h4, h5, h6 {
  @apply text-dark-blue-100;
}

a {
  @apply text-dark-blue-300 hover:text-dark-blue-100;
}

.button {
  @apply bg-dark-blue-500 text-gray-100 hover:bg-dark-blue-600 px-4 py-2 rounded;
}

.sidebar {
  @apply bg-dark-blue-900 text-gray-300;
}

.content {
  @apply bg-gray-200 text-dark-blue-800;
}

.footer {
  @apply bg-dark-blue-900 text-gray-400;
}

/* Add more custom styles as needed */